import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import ImagenDependencias from "../imagenes/ImagenDependencias";
import ImagenApp from "../imagenes/ImagenApp";
import ImagenOverview from "../imagenes/ImagenOverview";

import "./footer.css";

function Footer() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <footer className="bottom-0 bg-dark sin-pad">
        <Row className="centrado">
          <Col>
            <p className="txt-gris txt-chico marg-izq mt">
              Architecture
              <br /> Design <br /> Programming
            </p>
          </Col>
          <Col className=" m-4">
            <button
              className="btn btn-outline-light btn-sm"
              data-toggle="modal"
              onClick={handleShow}
            >
              made with ♡ by me
            </button>
          </Col>
          <Col>
            <p className="txt-gris justify-content-end txt-chico mr">
              © 2023 Copyright nicolasf.uy <br />
              All Rights Reserved
            </p>
          </Col>
        </Row>
      </footer>
      <Modal
        show={show}
        onHide={handleClose}
        className=""
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        size="xl"
      >
        <div className="margenes-30">
          <div className="modal-header sin-marg">
            <h3>
              about <b>nicolasf.uy</b>
            </h3>
          </div>
          <p className="justify">
            <br />
            This project utilizes a variety of external packages and libraries
            to facilitate its development and operation. These dependencies
            include "@reduxjs/toolkit" for state management,
            "@testing-library/jest-dom", "@testing-library/react", and
            "@testing-library/user-event" for testing React components,
            "bootstrap" and "bootstrap-icons" for styling with the Bootstrap
            framework, "chart.js" and "react-chartjs-2" for creating dynamic
            charts and graphs, "classnames" for conditionally joining classNames
            together, "emailjs-com" for sending emails through an API, "react"
            and "react-dom" for building user interfaces, "react-bootstrap" for
            responsive UI components with Bootstrap and React, "react-redux" for
            integrating Redux with React, "react-router-dom" for routing and
            navigation, "react-scripts" for running scripts and configuring the
            development environment, "sass" for writing CSS stylesheets with
            nested syntax and variables, and "web-vitals" for measuring and
            reporting performance.
            <br />
          </p>
          <Row>
            <Col className="col-12 col-lg-6">
              <ImagenDependencias />
            </Col>
            <Col className="col-12 col-lg-6">
              <ImagenApp />
            </Col>
          </Row>
          <p className="justify">
            <br />
            This page is made with a React functional component named "App".
            This component renders the overall structure of the application. It
            uses the "Provider" from "react-redux" to provide the Redux store to
            all the child components. It contains a "header" and "footer"
            component which display navigation and site information with this
            "Modal". The "BrowserRouter" component from "react-router-dom" is
            used to define routes for the different pages of the application. It
            includes various "Route" components that map to different paths of
            the application and display their corresponding components such as
            "Inicio", "SobreMi", "Arquitectura", "Diseno", and "Programacion".
          </p>
          <ImagenOverview />
          <p className="justify">
            <br />
            <br />
            This page is fully responsive. I'm committed to making this page as
            accessible as possible. I understand the importance of accessibility
            and I'm always looking to contribute to the community. If you see
            something out of place, or feel there is something to improve,
            please let me know.
            <br />
            <br />
            My next step in this project is to add the possibility to translate
            all the page, changing languages from English to Spanish, and vice
            versa. It is being written in English (<i>and in Spanish</i>) by me,
            to showcase my skills with the English language.
          </p>
          <div className="modal-footer">
            <p className="gris-cursiva">
              © 2023 Copyright nicolasf.uy All Rights Reserved &nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>{" "}
            <button
              type="button"
              className="btn btn-dark"
              data-dismiss="modal"
              onClick={handleClose}
            >
              close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Footer;
