import "../../../src/App.css";
import React from "react";

import ImgComp from "../../img/todos/overview.png";

const ImagenOverview = () => {
  return (
    <>
      <img className="centrado img-fluid" src={ImgComp} alt="" />
    </>
  );
};

export default ImagenOverview;
