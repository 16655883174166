import React from "react";
import { Col } from "react-bootstrap";
import { useState } from "react";
import ImagenIDATHA from "../imagenes/ImagenIDATHA";
import ImagenTREK from "../imagenes/ImagenTREK";
import ImagenNicolasf from "../imagenes/ImagenNicolasf";
import ImagenSantoDomingo from "../imagenes/ImagenSantoDomingo";
import ImagenLCU from "../imagenes/ImagenLCU";
import ImagenCogSol from "../imagenes/ImagenCogSol";
import "./sobremi.css";

const SobreMiExperiencia = () => {
  const [showMore, setShowMore] = useState("none");

  let mensaje = "see more";
  if (showMore === "block") {
    mensaje = "see less";
  }

  const onClick = () => {
    if (showMore === "none") {
      setShowMore("block");
    } else {
      setShowMore("none");
    }
  };

  return (
    <Col className="col-12 mx-auto marg-ab-card2">
      <h1 className="card-title m-md-4 text-start hastag">
        #professional experience
      </h1>
      <div style={{ height: "2vh" }} />
      <div className="margen-der marg-izq-sob">
        {/******************* COG SOL *****************/}
        <div className="d-flex flex-row">
          <ImagenCogSol />
          <span className="mx-3 mb-3">
            <p className="card-title fw-bold">Pyxis Cognitive Solutions</p>
            <p className="card-title tamanoCargo">Full stack developer</p>
          </span>
        </div>

        <h6 className="card-text mt-3 mb-3">Nov 2023 - present</h6>
        <p className="sub">
          A platform designed to build Generative AI assistants and agents for
          real business cases. Our platform builds assistants that can guide the
          user through one or several processes of an organization, such as the
          carbon footprint certification process or assembling a customized
          travel package tailored to the user's needs. The assistants can also
          answer questions about a specific domain. For example, a set of
          regulations of an organization, or articles and studies of an
          institution. The result is an assistant that reliably responds in a
          "tuned" way following the style and feedback of the domain and/or
          process experts within the organization.
          <br />
          <br />
          <a
            className="enlace"
            href="https://cs.pyxis.tech"
            target="_blank"
            rel="noreferrer"
          >
            cs.pyxis.tech{" "}
          </a>
        </p>

        <br />
        {/******************* IDATHA *****************/}
        <div className="d-flex flex-row">
          <ImagenIDATHA />
          <span className="mx-3 mb-3">
            <p className="card-title fw-bold">IDATHA</p>
            <p className="card-title tamanoCargo">Full stack developer</p>
          </span>
        </div>
        <h6 className="card-text mt-3 mb-3">Jan 2023 - present</h6>
        <p className="sub">
          {" "}
          We are a team of engineers passionate about Machine Learning, Data
          Science, Big Data, Natural Language Processing, Image Processing and
          Social Media Analytics. We work to position ourselves as a reference
          in these areas. We aim to develop innovative products that generate
          accurate information and valuable knowledge for companies and society.
          We provide consulting and development services to share our knowledge.
          <br />
          <br />{" "}
          <a
            className="enlace"
            href="https://www.idatha.com/"
            target="_blank"
            rel="noreferrer"
          >
            idatha.com{" "}
          </a>
        </p>
        <br />
        {/******************* SANTO DOMINGO *****************/}
        <div className="d-flex flex-row">
          <ImagenSantoDomingo />
          <span className="mx-3 mb-3">
            <p className="card-title fw-bold">Colegio y Liceo Santo Domingo</p>
            <p className="card-title tamanoCargo">Guest professor</p>
          </span>
        </div>

        <h6 className="card-text mt-3 mb-3">Aug 2019 - present</h6>
        <p className="sub">
          I have been participating since 2019 as a visiting professor,
          conducting talks and multidisciplinary workshops for high school
          architecture-oriented students, being part of their final course work,
          covering various subjects.
          <br />
          <br />
          <a
            className="enlace"
            href="https://www.santodomingo.edu.uy/"
            target="_blank"
            rel="noreferrer"
          >
            santodomingo.edu.uy{" "}
          </a>
        </p>

        <br />
        {/******************* ARQ INDEPENDIENTE *****************/}
        <div className="d-flex flex-row">
          <ImagenNicolasf />
          <span className="mx-3 mb-3">
            <p className="card-title fw-bold">Independent professional</p>
            <p className="card-title tamanoCargo">Architect</p>
          </span>
        </div>
        <h6 className="card-text mt-3 mb-3">Jun 2018 - present</h6>
        <p className="sub">
          Independent professional with 7 years of experience in the field. If
          you think I can help you to achieve your idea become a reality, don't
          hesitate to contact me, let's talk!
          <br />
          Services offered: <br />
          New construction / Reform / Preliminary Project / Executive Project /
          Construction Management / Regulations and Procedures / Design
          <br />
          <br />{" "}
          <a
            className="enlace"
            href="https://nicolasf.uy/"
            target="_blank"
            rel="noreferrer"
          >
            nicolasf.uy
          </a>
        </p>
        <br />
        {/******************* VER MAS *****************/}
        <div id="vermas" style={{ display: showMore }}>
          <br />

          {/******************* ARQ INDEPENDIENTE *****************/}
          <div className="d-flex flex-row">
            <ImagenLCU />
            <span className="mx-3 mb-3">
              <p className="card-title fw-bold">La Casa Uruguaya</p>
              <p className="card-title tamanoCargo">
                Architect / founding partner
              </p>
            </span>
          </div>

          <h6 className="card-text mt-3 mb-3">Apr 2016 - Dic 2022</h6>
          <p className="sub">
            {" "}
            Founding partner and part of the team of architects of the studio
            and construction company La Casa Uruguaya. From september 2021 to
            december 2022 I assume a sales and marketing director role.
            <br />
            <br />
            LCU is a company that advocates sustainability, innovation and
            efficiency; proud to be a green company. Sustainable architecture is
            a branch of architecture that sees the building as an integral part
            of its environment.
            <br />
            <br /> Our vision seeks to raise awareness and offer knowledge about
            energy saving and bioclimatic strategies to improve the functioning
            of the house, applying different construction techniques, in order
            to reduce the ecological impact of each home.
            <br />
            <br />{" "}
            <a
              className="enlace"
              href="https://www.lacasauruguaya.com/"
              target="_blank"
              rel="noreferrer"
            >
              lacasauruguaya.com
            </a>
          </p>
          <br />
          {/******************* TREK *****************/}
          <div className="d-flex flex-row">
            <ImagenTREK />
            <span className="mx-3 mb-3">
              <p className="card-title fw-bold">Trek</p>
              <p className="card-title tamanoCargo">
                Design and marketing director
              </p>
            </span>
          </div>

          <h6 className="card-text mt-3 mb-3">Nov 2018 - Aug 2021</h6>
          <p className="sub">
            Started at the end of October 2018 as a creative, graphic designer
            and community manager for the recently open Trek Tres Cruces.
            <br /> In March 2019, I began to carry out the same tasks for the
            already existing commercial premises of Trek Punta Carretas. <br />
            <br />
            In January 2020, in my role as an architect, I participated in the
            reform and design of a commercial premises for Trek Las Piedras, for
            which I later went on to carry out the same tasks, now for the 3
            commercial premises. For these 3 stores, a team was necessary, so a
            team of designers, creatives and community managers was assembled.{" "}
            <br />
            <br />I moved into a managerial role, applying agile methodologies
            and productive software tools, to create and supervise all tasks. In
            May 2020 I was in charge of building an ecommerce system under the
            RoloBikes brand, performing and managing the same tasks as for a
            physical store, in addition to designing and keeping the website and
            database updated. From then on I was in charge of managing the 3
            physical stores and the online store. Left the company at the end of
            August 2021.
          </p>

          <br />
          {/******************* DIBUJANTE *****************/}

          <div className="d-flex flex-row">
            <ImagenNicolasf />
            <span className="mx-3 mb-3">
              <p className="card-title fw-bold">Freelance</p>
              <p className="card-title tamanoCargo">Technical draftsman</p>
            </span>
          </div>

          <h6 className="card-text mt-3 mb-3">2007 - 2014</h6>
          <p className="sub">
            While I was finishing high school I trained at Taller de Informática
            and obtained my AutoCAD draftsman certification. From there I work
            as a draftsman helping regular architects to transcribe and/or draw
            plans on their preferred software.
          </p>
          <br />
        </div>
        <button className="btn btn-sm btn-outline-dark" onClick={onClick}>
          {mensaje}
        </button>
      </div>
    </Col>
  );
};

export default SobreMiExperiencia;
