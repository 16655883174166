import "../../../src/App.css";
import React from "react";

import ImgComp from "../../img/sobremi/genexus.png";

const ImagenGenexus = () => {
  return (
    <img
      className="rounded-circle"
      src={ImgComp}
      alt=""
      style={{ height: "65px" }}
    />
  );
};

export default ImagenGenexus;
