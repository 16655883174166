import "../../../src/App.css";

import React from "react";

import ImgComp from "../../img/inicio/diseno.webp";

const ImagenDiseno = () => {
  return <img className="ancho-total tamano fit" src={ImgComp} alt="" />;
};

export default ImagenDiseno;
