import "./navegacion.css";

import React from "react";
import { useState } from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import ImagenNav from "../imagenes/ImagenNav";
import BanderaEspania from "../imagenes/BanderaEspania";
import BanderaUsa from "../imagenes/BanderaUsa";

function Navegacion() {
  const [colorBgNav, setColorBgNav] = useState("white");
  const [colorTxtNav, setColorTxtNav] = useState("txt-enlace txt-black");
  const [colorNav, setColorNav] = useState("");

  const [colorIconMenu, setColorIconMenu] = useState(
    "sticky padding-lados borde-2"
  );

  const changeBackground = () => {
    if (window.scrollY >= 500 && window.location.pathname === "/") {
      setColorBgNav("dark");
      setColorIconMenu("sticky padding-lados borde-2 navbar-dark");
    } else {
      setColorBgNav("white");
      setColorIconMenu("sticky-top padding-lados borde-2 navbar-light");
    }
  };
  const changeTextColor = () => {
    if (window.scrollY >= 500 && window.location.pathname === "/") {
      setColorTxtNav("txt-enlace txt-white");
    } else {
      setColorTxtNav("txt-enlace txt-black");
    }
  };

  const changeColorNav = () => {
    if (window.location.pathname === "/arquitectura") {
      setColorNav("bg-naranja");
    } else if (window.location.pathname === "/diseno") {
      setColorNav("bg-azul");
    } else if (window.location.pathname === "/programacion") {
      setColorNav("bg-fluor");
    }
  };

  window.addEventListener("scroll", changeBackground);
  window.addEventListener("scroll", changeTextColor);

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      bg={colorBgNav}
      variant="white"
      className={colorIconMenu}
      id={colorNav}
      onLoad={changeColorNav}
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <div>&nbsp;</div>
      <Navbar.Brand>
        {""}
        <a href="/">
          <ImagenNav className="padding-lados" />
        </a>
      </Navbar.Brand>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <Nav.Link className={colorTxtNav} href="/arquitectura">
            architecture
          </Nav.Link>
          <Nav.Link className={colorTxtNav} href="/diseno">
            design
          </Nav.Link>
          <Nav.Link className={colorTxtNav} href="/programacion">
            programming
          </Nav.Link>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </Nav>
        <Nav className="">
          <Nav.Link className={[colorTxtNav]} href="#">
            {/*<BanderaEspania />*/}
          </Nav.Link>
          <Nav.Link className={[colorTxtNav]} href="#">
            {/* <BanderaUsa /> */}
          </Nav.Link>
        </Nav>
        <Nav className="padding-lados">
          <Nav.Link className={[colorTxtNav]} href="/sobremi">
            about me
          </Nav.Link>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navegacion;
