import "../../../src/App.css";
import "../inicio/inicioGrilla.css";

import React from "react";

import ImgComp from "../../img/inicio/Github.jpg";

const ImagenGithub = () => {
  return (
    <img
      className="rounded-circle position-absolute marg-der imag-card tamano7 end-0 top-0 shadow-lg"
      src={ImgComp}
      alt=""
    />
  );
};

export default ImagenGithub;
