import "./arquitectura.css";

import React from "react";
import { Col, Row } from "react-bootstrap";

import ArquitecturaGrilla from "./ArquitecturaGrilla";
import ArquitecturaServicios from "./ArquitecturaServicios";
import ArquitecturaTexto from "./ArquitecturaTexto";

const Arquitectura = () => {
  return (
    <>
      <div className="just marg-lados">
        <br />
        <h1 className="marg-arriba h1-arq">architecture</h1>
        <Row>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <ArquitecturaTexto />
          </Col>
          <br />
          <Col className="m-2">
            <ArquitecturaGrilla />
            <p className="secundario text-end">
              These photographs showcase a collection of projects on which I had
              the pleasure of working.
            </p>
          </Col>
        </Row>
        <ArquitecturaServicios />
      </div>
    </>
  );
};

export default Arquitectura;
