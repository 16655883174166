import React from "react";
import { Col } from "react-bootstrap";
import "./inicioGrilla.css";

const InicioTitulo = () => {
  return (
    <Col className="col-12">
      <div>
        <h1 className="h1-inicio" style={{ color: "black" }}>
          nicolas fernandez
        </h1>
        <h2 className="h2-inicio tamano5" style={{ color: "black" }}>
          #architect #it_analyst
        </h2>
      </div>
    </Col>
  );
};

export default InicioTitulo;
