import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";

import Navegacion from "./components/todos/Navegacion";
import Footer from "./components/todos/Footer";

import Inicio from "./components/inicio/Inicio";
import Arquitectura from "./components/arquitectura/Arquitectura";
import Diseno from "./components/diseno/Diseno";
import Programacion from "./components/programacion/Programacion";
import SobreMi from "./components/sobremi/SobreMi";

function App() {
  return (
    <Provider store={store}>
      <header className="fixed-top">
        <Navegacion />
      </header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/sobremi" element={<SobreMi />} />
          <Route path="/arquitectura" element={<Arquitectura />} />
          <Route path="/diseno" element={<Diseno />} />
          <Route path="/programacion" element={<Programacion />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </Provider>
  );
}
export default App;
