import React, { useState } from "react";

import "./programacion.css";

import { generateContent } from "../../api/client";

const Programacion = () => {
  const [prompt, setPrompt] = useState("");
  const [output, setOutput] = useState([]);

  const [hasOutput, setHasOutput] = useState(false);

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleSubmit = async (event) => {
    if (prompt === "") {
      setOutput("Please enter a question.");
    }
    event.preventDefault();
    setOutput("Generating...");

    const response = await generateContent(prompt);
    console.log("Respuesta: ", response);
    setOutput(response.content.parts[0].text);
    setHasOutput(true);
    console.log("Output: ", output);
  };

  return (
    <div className="">
      <br />
      <h1 className="marg-arriba h1-prog">programming</h1>
      <br />
      <br />
      <br />
      <br />
      <h1 className="centrado">coming soon</h1>
      <h2 className="h2-prog centrado">
        while i'm making this page, ask the AI anything
      </h2>
      <div className="centrado">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={prompt}
            onChange={handlePromptChange}
            placeholder="Enter your question here"
            style={{
              textAlign: "center",
              width: "40%",
              minHeight: "35px",
              fontSize: "16px",
              borderRadius: "0.375rem",
              border: "1px solid #d2d6dc",
              boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
            }}
          />
          <button
            className="btn btn-outline-dark btn-sm"
            type="submit"
            style={{ marginLeft: "20px" }}
          >
            Generate
          </button>
        </form>
        <p
          style={{
            textAlign: "center",
            fontSize: "0.75rem",
            color: "#718096",
            marginTop: "1rem",
            marginBottom: "1rem",
            marginLeft: "10rem",
            marginRight: "10rem",
          }}
        >
          This tool is powered by Gemini Pro, a language model trained by
          Google. I made this interface to test the API and the model itself.
        </p>
        <div
          style={{
            marginLeft: "8rem",
            marginRight: "8rem",
            textAlign: "justify",
            marginTop: "2.5rem",
            fontSize: "1.125rem",
          }}
        >
          {output}
        </div>
        {hasOutput ? (
          <div className="centrado">
            <a
              className="btn btn-outline-dark btn-sm"
              href={`https://www.google.com/search?q=${prompt}`}
              target="_blank"
              rel="noreferrer"
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                marginLeft: "10rem",
                marginRight: "10rem",
              }}
            >
              Search on Google
            </a>
            <a
              className="btn btn-outline-dark btn-sm"
              href={`data:text/plain;charset=utf-8,${output}`}
              download={`${prompt}.txt`}
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                marginLeft: "10rem",
                marginRight: "10rem",
              }}
            >
              Save as .txt
            </a>
          </div>
        ) : null}
      </div>
      <div className="centrado" style={{ height: "25vh" }}></div>
    </div>
  );
};

export default Programacion;
