import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./arquitectura.css";

import React, { useMemo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

import imagen1 from "../../img/arquitectura/imagen1.png";
import imagen10 from "../../img/arquitectura/imagen10.png";
import imagen11 from "../../img/arquitectura/imagen11.png";
import imagen12 from "../../img/arquitectura/imagen12.png";
import imagen13 from "../../img/arquitectura/imagen13.png";
import imagen14 from "../../img/arquitectura/imagen14.png";
import imagen15 from "../../img/arquitectura/imagen15.png";
import imagen16 from "../../img/arquitectura/imagen16.png";
import imagen17 from "../../img/arquitectura/imagen17.png";
import imagen18 from "../../img/arquitectura/imagen18.png";
import imagen19 from "../../img/arquitectura/imagen19.png";
import imagen2 from "../../img/arquitectura/imagen2.png";
import imagen20 from "../../img/arquitectura/imagen20.png";
import imagen21 from "../../img/arquitectura/imagen21.png";
import imagen22 from "../../img/arquitectura/imagen22.png";
import imagen23 from "../../img/arquitectura/imagen23.png";
import imagen24 from "../../img/arquitectura/imagen24.png";
import imagen25 from "../../img/arquitectura/imagen25.png";
import imagen26 from "../../img/arquitectura/imagen26.png";
import imagen27 from "../../img/arquitectura/imagen27.png";
import imagen28 from "../../img/arquitectura/imagen28.png";
import imagen3 from "../../img/arquitectura/imagen3.png";
import imagen4 from "../../img/arquitectura/imagen4.png";
import imagen5 from "../../img/arquitectura/imagen5.png";
import imagen6 from "../../img/arquitectura/imagen6.png";
import imagen7 from "../../img/arquitectura/imagen7.png";
import imagen8 from "../../img/arquitectura/imagen8.png";
import imagen9 from "../../img/arquitectura/imagen9.png";

const ArquitecturaGrilla = () => {
  const layout = [
    {
      i: "1",
      x: 0,
      y: 0,
      w: 5,
      h: 2,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "2",
      x: 5,
      y: 0,
      w: 3,
      h: 1.3,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "3",
      x: 8,
      y: 0,
      w: 3,
      h: 1.3,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "4",
      x: 11,
      y: 0,
      w: 4,
      h: 2,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "5",
      x: 0,
      y: 3,
      w: 5,
      h: 1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "6",
      x: 5,
      y: 2,
      w: 6,
      h: 1.7,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "7",
      x: 0,
      y: 5,
      w: 4,
      h: 1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "8",
      x: 4,
      y: 5,
      w: 3,
      h: 1.5,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "9",
      x: 7,
      y: 3,
      w: 4,
      h: 1.3,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "10",
      x: 11,
      y: 4,
      w: 4,
      h: 2,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "11",
      x: 0,
      y: 8,
      w: 4,
      h: 1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "12",
      x: 0,
      y: 20,
      w: 4,
      h: 1.1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "13",
      x: 0,
      y: 8,
      w: 4,
      h: 1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "14",
      x: 11,
      y: 6,
      w: 4,
      h: 1.2,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "15",
      x: 4,
      y: 6,
      w: 3,
      h: 1.5,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "16",
      x: 11,
      y: 9,
      w: 4,
      h: 1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "17",
      x: 7,
      y: 9,
      w: 4,
      h: 0.9,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "18",
      x: 7,
      y: 4,
      w: 4,
      h: 1.2,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "19",
      x: 0,
      y: 11,
      w: 3,
      h: 1.4,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "20",
      x: 3,
      y: 11,
      w: 4,
      h: 1.4,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "21",
      x: 7,
      y: 11,
      w: 4,
      h: 1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "22",
      x: 0,
      y: 30,
      w: 4,
      h: 0.8,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "23",
      x: 11,
      y: 21,
      w: 4,
      h: 1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "24",
      x: 11,
      y: 30,
      w: 4,
      h: 1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "25",
      x: 8,
      y: 20,
      w: 3,
      h: 1.9,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "26",
      x: 11,
      y: 24,
      w: 4,
      h: 1.1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "27",
      x: 4,
      y: 24,
      w: 4,
      h: 1.1,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
    {
      i: "28",
      x: 4,
      y: 23,
      w: 4,
      h: 0.8,
      minW: 0,
      maxW: Infinity,
      minH: 0,
      maxH: Infinity,
    },
  ];

  const cards = [
    { id: "1", title: "Card 1", alt: "Card 1", img: imagen1 },
    { id: "2", title: "Card 2", alt: "Card 2", img: imagen2 },
    { id: "3", title: "Card 3", alt: "Card 3", img: imagen3 },
    { id: "4", title: "Card 4", alt: "Card 4", img: imagen4 },
    { id: "5", title: "Card 5", alt: "Card 5", img: imagen5 },
    { id: "6", title: "Card 6", alt: "Card 6", img: imagen6 },
    { id: "7", title: "Card 7", alt: "Card 7", img: imagen7 },
    { id: "8", title: "Card 8", alt: "Card 8", img: imagen8 },
    { id: "9", title: "Card 9", alt: "Card 9", img: imagen9 },
    { id: "10", title: "Card 10", alt: "Card 10", img: imagen10 },
    { id: "11", title: "Card 11", alt: "Card 11", img: imagen11 },
    { id: "12", title: "Card 12", alt: "Card 12", img: imagen12 },
    { id: "13", title: "Card 13", alt: "Card 13", img: imagen13 },
    { id: "14", title: "Card 14", alt: "Card 14", img: imagen14 },
    { id: "15", title: "Card 15", alt: "Card 15", img: imagen15 },
    { id: "16", title: "Card 16", alt: "Card 16", img: imagen16 },
    { id: "17", title: "Card 17", alt: "Card 17", img: imagen17 },
    { id: "18", title: "Card 18", alt: "Card 18", img: imagen18 },
    { id: "19", title: "Card 19", alt: "Card 19", img: imagen19 },
    { id: "20", title: "Card 20", alt: "Card 20", img: imagen20 },
    { id: "21", title: "Card 21", alt: "Card 21", img: imagen21 },
    { id: "22", title: "Card 22", alt: "Card 22", img: imagen22 },
    { id: "23", title: "Card 23", alt: "Card 23", img: imagen23 },
    { id: "24", title: "Card 24", alt: "Card 24", img: imagen24 },
    { id: "25", title: "Card 25", alt: "Card 25", img: imagen25 },
    { id: "26", title: "Card 26", alt: "Card 26", img: imagen26 },
    { id: "27", title: "Card 27", alt: "Card 27", img: imagen27 },
    { id: "28", title: "Card 28", alt: "Card 28", img: imagen28 },
  ];

  const breakpoints = {
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
  };
  const cols = { lg: 15, md: 15, sm: 15, xs: 15, xxs: 3 };

  const Card = ({ id, alt, img }) => (
    <div className="card w-100 h-100 bg-transparent" key={id}>
      <img
        id={id}
        src={img}
        className="card-img"
        alt={alt}
        height={"100%"}
        width={"100%"}
        object-fit="scale-down"
        style={{ objectFit: "cover" }}
      />
    </div>
  );

  const ResponsiveReactGridLayout = useMemo(
    () => WidthProvider(Responsive),
    []
  );

  return (
    <ResponsiveReactGridLayout
      className="layout"
      layouts={{
        lg: layout,
        md: layout,
        sm: layout,
        xs: layout,
        xxs: layout,
      }}
      breakpoints={breakpoints}
      cols={cols}
      isResizable={false}
      isDraggable={false}
    >
      {cards.map((card) => (
        <div key={card.id} data-grid={{ w: "100%", h: "100%" }}>
          <Card {...card} />
        </div>
      ))}
    </ResponsiveReactGridLayout>
  );
};

export default ArquitecturaGrilla;
