import React from "react";
import ImgComp from "../../img/todos/firma.png";

const ImagenFirma = () => {
  return (
    <>
      <img className="" src={ImgComp} alt="" style={{ height: "80px" }} />
    </>
  );
};

export default ImagenFirma;
