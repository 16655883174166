import "../todos/navegacion.css";

import { useState } from "react";

import ImgCompBlanco from "../../img/todos/firma.png";
import ImgCompNegro from "../../img/todos/firma2.png";

const ImagenNav = () => {
  const [colorLogo, setColorLogo] = useState(ImgCompNegro);

  const changeColorLogo = () => {
    if (window.scrollY >= 500 && window.location.pathname === "/") {
      setColorLogo(ImgCompBlanco);
    } else {
      setColorLogo(ImgCompNegro);
    }
  };

  window.addEventListener("scroll", changeColorLogo);
  window.addEventListener("load", changeColorLogo);

  return (
    <img className="padding-logo tam-fijo" src={colorLogo} alt="Nicolas" />
  );
};

export default ImagenNav;
