import "./diseno.css";

import React from "react";

const Diseno = () => {
  return (
    <div className="just marg-lados ancho-total" style={{ height: "100vh" }}>
      <br />
      <h1 className="marg-arriba h1-diseno">design</h1>
      <div class="centered">
        <h1>coming soon</h1>
        <h2>stay tuned</h2>
      </div>
    </div>
  );
};

export default Diseno;
