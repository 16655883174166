import React from "react";
import ImgComp from "../../img/inicio/qr.png";

const ImagenQR = () => {
  return (
    <>
      <img className="" src={ImgComp} alt="" style={{ height: "120px" }} />
    </>
  );
};

export default ImagenQR;
