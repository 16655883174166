import React from "react";
import "./inicio.css";
import { Col, Row } from "react-bootstrap";
import InicioCarrousell from "./InicioCarrousell";
import InicioGrilla from "./InicioGrilla";

const MemoizedInicioCarrousell = React.memo(InicioCarrousell);

const Inicio = () => {
  return (
    <>
      <div className="borde-abj-20 ancho-total">
        <MemoizedInicioCarrousell />
      </div>
      <Row>
        <Col className="bg-inicio" style={{ padding: "20px" }}>
          <InicioGrilla />
        </Col>
      </Row>
    </>
  );
};

export default Inicio;
