import "./inicioGrilla.css";

import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { guardarPaises } from "../../features/paisesSlice";

import InicioContacto from "./InicioContacto";
import InicioGithub from "./InicioGithub";
import InicioLinkedin from "./InicioLinkedin";
import InicioMailyCel from "./InicioMailyCel";
import InicioTexto from "./InicioTexto";
import InicioTitulo from "./InicioTitulo";
import InicioVCard from "./InicioVCard";

const InicioGrilla = () => {
  const dispatch = useDispatch();
  const urlPaises = `https://restcountries.com/v3.1/all`;
  useEffect(() => {
    fetch(urlPaises, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((respuesta) => respuesta.json())
      .then((data) => {
        dispatch(guardarPaises(data));
      });
  });

  return (
    <Fragment>
      <Row className="marg-lados">
        <InicioTitulo />
        <InicioTexto />
        <Col>
          <Col className="col-lg-12 col-md-12 col-sm-9 col-xl-10 col-12 marg-inf marg-sup mx-auto">
            <InicioLinkedin />
          </Col>
          <Col className="col-lg-12 col-xl-10 col-md-12 col-sm-9 col-12 marg-inf marg-sup mar mx-auto">
            <InicioGithub />
          </Col>
        </Col>
      </Row>
      <Row className="marg-lados">
        <Col className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto">
          <InicioContacto />
        </Col>
        <Col className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <Col className="col-12 mx-auto">
            <InicioVCard />
          </Col>
          <Col className="col-12 mx-auto">
            <InicioMailyCel />
          </Col>
        </Col>
      </Row>
      <div className="marg-abj-final"></div>
    </Fragment>
  );
};

export default InicioGrilla;
