import React from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { Row, Col, Card, Button } from "react-bootstrap";
import { send } from "emailjs-com";

import { useSelector } from "react-redux";

import "./inicioGrilla.css";

const InicioContacto = () => {
  const paises = useSelector((state) => state.paises.paises);
  let paisesOrd = [...paises].sort((a, b) =>
    a.translations.spa.common > b.translations.spa.common ? 1 : -1
  );

  const service_id = "nicolasf_service";
  const template_id = "nicolasf_id";
  const user_id = "Op1QXB4HAWzglhPCZ";

  const [toSend, setToSend] = useState({
    from_name: "",
    reply_to: "",
    to_name: "contacto@nicolasf.uy",
    phone: "",
    checkboxArq: "",
    checkboxDis: "",
    checkboxProg: "",
    checkboxMar: "",
    message: "",
    pais: "Uruguay",
  });

  let mensaje_submit = document.getElementById("mensaje_submit");

  const onSubmit = (e) => {
    e.preventDefault();
    send(service_id, template_id, toSend, user_id);
    if (send) {
      mensaje_submit.innerHTML =
        "¡Message sent! I will contact you soon, thanks!";
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <>
      <h1 className="h1-inicio2 tamano4" style={{ color: "black" }}>
        contact
      </h1>
      <Card
        className="bg-light shadow card-body marg-ab-card"
        style={{ height: "auto" }}
      >
        <Form onSubmit={onSubmit} className="col-12">
          <Row>
            <Col className="col-7">
              <Form.Group className="mb-3">
                <Form.Label htmlFor="from_name">name </Form.Label>
                <Form.Control
                  className="hover-negro"
                  type="text"
                  required
                  name="from_name"
                  value={toSend.from_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col className="col-5">
              <Form.Label htmlFor="pais">country </Form.Label>
              <Form.Select
                className="hover-negro"
                name="pais"
                defaultValue={"default"}
                onChange={handleChange}
              >
                <option value="Uruguay">Uruguay</option>
                <option value="--------" disabled></option>
                {paisesOrd.map((element) => {
                  return (
                    <option
                      key={element.translations.spa.common}
                      value={element.translations.spa.common}
                    >
                      {element.translations.spa.common}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            <Col className="col-7">
              <Form.Label htmlFor="reply_to">email </Form.Label>
              <Form.Control
                className="hover-negro"
                type="text"
                required
                name="reply_to"
                value={toSend.reply_to}
                onChange={handleChange}
              />
            </Col>
            <Col className="col-5">
              <Form.Label htmlFor="phone">phone </Form.Label>
              <Form.Control
                className="hover-negro"
                type="text"
                required
                name="phone"
                value={toSend.phone}
                onChange={handleChange}
              />
            </Col>
            <Col className="col-5 marg-sup-2">
              <Form.Label htmlFor="checkboxArq">area </Form.Label>
              <fieldset value={toSend.checkboxArq} onChange={handleChange}>
                <div>
                  <input
                    className="hover-negro"
                    type="checkbox"
                    name="checkboxArq"
                    value="Arquitectura"
                  />
                  <Form.Label name="checkboxArq" className="marg-izq text-sm">
                    architecture
                  </Form.Label>
                </div>
              </fieldset>
              <fieldset value={toSend.checkboxDis} onChange={handleChange}>
                <div>
                  <input
                    type="checkbox"
                    name="checkboxDis"
                    value="Diseño"
                    className="hover-negro"
                  />
                  <Form.Label
                    className="marg-izq text-sm"
                    name="checkboxDis"
                    htmlFor="checkboxDis"
                  >
                    design
                  </Form.Label>
                </div>
              </fieldset>
              <fieldset value={toSend.checkboxMar} onChange={handleChange}>
                <div>
                  <input
                    type="checkbox"
                    name="checkboxMar"
                    value="Marketing"
                    className="hover-negro"
                  />
                  <Form.Label
                    className="marg-izq text-sm"
                    name="checkboxMar"
                    htmlFor="checkboxMar"
                  >
                    marketing
                  </Form.Label>
                </div>
              </fieldset>
              <fieldset value={toSend.checkboxProg} onChange={handleChange}>
                <div className="mb-3">
                  <div>
                    <input
                      className="hover-negro"
                      name="checkboxProg"
                      type="checkbox"
                      value="Programación"
                    />
                    <Form.Label
                      className="marg-izq text-sm"
                      name="checkboxProg"
                      htmlFor="checkboxProg"
                    >
                      programming
                    </Form.Label>
                  </div>
                </div>
              </fieldset>
            </Col>
            <Col className="col-7 marg-sup-2">
              <Form.Label htmlFor="from_name">message </Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                required
                rows="5"
                name="message"
                value={toSend.message}
                onChange={handleChange}
                className="marg-abj-btn hover-negro"
              />
            </Col>
            <Col className="col-12">
              <Button className="col-12" type="submit" variant="dark">
                Send
              </Button>
            </Col>
            <p
              className="text-success text-center fw-bolder mt-3 mb-neg"
              id="mensaje_submit"
            ></p>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default InicioContacto;
