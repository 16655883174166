import React, { useMemo } from "react";
import "./iniciocarrousell.css";

import Carousel from "react-bootstrap/Carousel";

import ImagenArquitectura from "../imagenes/ImagenArquitectura";
import ImagenDiseno from "../imagenes/ImagenDiseno";
import ImagenProgramacion from "../imagenes/ImagenProgramacion";

const InicioCarrousell = () => {
  const memoizedImagenArquitectura = useMemo(() => <ImagenArquitectura className="w-100" />, []);
  const memoizedImagenDiseno = useMemo(() => <ImagenDiseno className="w-100" />, []);
  const memoizedImagenProgramacion = useMemo(() => <ImagenProgramacion className="w-100" />, []);

  return (
    <Carousel pause="false" className="w-100 mx-auto" nextIcon="" prevIcon="">
      <Carousel.Item interval={2150}>
        <h1 className="txt-size pos-arr-der naranja tamano2 sin-pad">
          architecture
        </h1>
        {memoizedImagenArquitectura}
      </Carousel.Item>
      <Carousel.Item interval={2150}>
        <h1 className="txt-size pos-arr-der azul tamano2 sin-pad">design</h1>
        {memoizedImagenDiseno}
      </Carousel.Item>
      <Carousel.Item interval={2150}>
        <h1 className="txt-size pos-arr-der fluor tamano2 sin-pad">
          programming
        </h1>
        {memoizedImagenProgramacion}
      </Carousel.Item>
    </Carousel>
  );
};

export default InicioCarrousell;
