import "./sobremi.css";

import React from "react";
import { Col, Row } from "react-bootstrap";
import SobreMiTexto from "./SobreMiTexto";
import SobreMiFormacion from "./SobreMiFormacion";
import SobreMiExperiencia from "./SobreMiExperiencia";
import SobreMiCertificados from "./SobreMiCertificados";
import SobreMiPersonal from "./SobreMiPersonal";
import SobreMiCV from "./SobreMiCV";

const SobreMi = () => {
  return (
    <>
      <div className="marg-ab-card2">
        <br />
        <div className="marg-lados">
          <h1 className="marg-arriba-sob h1-sobre">about me</h1>
        </div>
        <br />
        <Row className="marg-lados-sob">
          <SobreMiTexto />
          <Col className="col-12 col-sm-11 col-md-11 col-lg-4 col-xl-4 mx-auto">
            <SobreMiFormacion />
            <br />
            <SobreMiExperiencia />
            <SobreMiCertificados />
            <br />
            <h1 className="card-title m-md-4 text-start hastag">
              #personal skills{" "}
            </h1>
            <div className="margen-der marg-izq-sob">
              <SobreMiPersonal />
            </div>
            <br />
            <br />
            <SobreMiCV />
            <br />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SobreMi;
