import React from "react";
import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

Chart.register(...registerables);

const labels = [
  "Creativity",
  "Innovation",
  "Teamwork",
  "Management",
  "Perseverance",
  "Spanish",
  "Portuguese",
  "English",
];
Chart.defaults.font.size = 15.5;
Chart.defaults.font.family = "rajdhani";
Chart.defaults.font.lineHeight = 0.5;

const data = {
  labels,
  datasets: [
    {
      label: "%",
      data: [100, 80, 90, 90, 100, 100, 60, 100],
      backgroundColor: "rgba(30, 30, 30, 0.8)",
    },
  ],
};

const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "hidden",
    },
    title: {
      display: false,
      text: "personal skills",
    },
  },
};

function SobreMiPersonal() {
  return <Bar options={options} data={data} />;
}

export default SobreMiPersonal;
