import "./arquitectura.css";

import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const ArquitecturaServicios = () => {
  return (
    <div
      className="marg-ab-card mx-2 mb-5"
      style={{
        height: "auto",
      }}
    >
      <h2 className="card-title h2-arq izquierda ms-3">services</h2>
      <Row className="mx-1 my-2">
        <Col className="col-12 col-sm-6 col-md-3 mb-3">
          <Card className="maximo">
            <Card.Body>
              <h5 className="centrado h-arq mt-1">new construction</h5>
              <br />
              <p className="secundario centrado">
                From concept to completion, I'll guide you through the entire
                process, ensuring your vision becomes a reality.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-6 col-md-3 mb-3">
          <Card className="maximo">
            <Card.Body>
              <h5 className="centrado h-arq mt-1">remodeling / renovation</h5>
              <br />
              <p className="secundario centrado">
                Enhance your existing spaces with an architectural renovation,
                carefully designed to meet your requirements efficiently.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-6 col-md-3 mb-3">
          <Card className="maximo">
            <Card.Body>
              <h5 className="centrado h-arq mt-1">preliminary project</h5>
              <br />
              <p className="centrado secundario">
                Lay the groundwork for your project with my detailed assessments
                and conceptual designs.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-6 col-md-3 mb-3">
          <Card className="maximo">
            <Card.Body>
              <h5 className="centrado h-arq mt-1">executive project</h5>
              <br />
              <p className="centrado secundario">
                Handling the technical drawings and specifications for seamless
                construction execution.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-6 col-md-3 mb-3">
          <Card className="maximo">
            <Card.Body>
              <h5 className="centrado h-arq mt-1">construction management</h5>
              <br />
              <p className="centrado secundario">
                Overseeing every stage of the construction process, ensuring
                quality and timely completion.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-6 col-md-3 mb-3">
          <Card className="maximo">
            <Card.Body>
              <h5 className="centrado h-arq mt-1">
                regulations and procedures
              </h5>
              <br />
              <p className="centrado secundario">
                {" "}
                Navigating regulations is complex, but I'll handle it all to
                ensure compliance and approvals.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-6 col-md-3 mb-3">
          <Card className="maximo">
            <Card.Body>
              <h5 className="centrado h-arq mt-1">design</h5>
              <br />
              <p className="secundario centrado">
                My innovative and functional designs will transform your space
                into something exceptional.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-6 col-md-3 mb-3">
          <Card className="maximo">
            <Card.Body>
              <br />
              <p className="centrado secundario ms-3 mt-3">
                If you believe that I can be of assistance in transforming your
                idea into a reality, please do not hesitate to contact me.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ArquitecturaServicios;
