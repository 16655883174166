import axios from "axios";

const generateContent = async (text) => {
  const apiKey = "AIzaSyBClPoURH2YUf0VpFKZ5YR6xWcFlMZmq0g";
  console.log(apiKey);
  const url = `https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=${apiKey}`;

  const data = {
    contents: [
      {
        parts: [
          {
            text: text,
          },
        ],
      },
    ],
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("API: ", response);
    return response.data.candidates[0];
  } catch (error) {
    console.error(error.toString());
  }
};

export { generateContent };
