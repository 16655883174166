import React, { useState } from "react";
import { Col } from "react-bootstrap";

import "./sobremi.css";
import ImagenGenexus from "../imagenes/ImagenGenexus";
import ImagenEnergia from "../imagenes/ImagenEnergia";
import ImagenTaller from "../imagenes/ImagenTaller";
import ImagenORT from "../imagenes/ImagenORT";

const SobreMiCertificados = () => {
  const [showMore, setShowMore] = useState("none");

  let mensaje = "see more";
  if (showMore === "block") {
    mensaje = "see less";
  }

  const onClick = () => {
    if (showMore === "none") {
      setShowMore("block");
    } else {
      setShowMore("none");
    }
  };

  function abrirSolar() {
    window.open(
      "https://nicolasfuy.s3.amazonaws.com/certificados/solardecathlon.pdf",
      "_blank"
    );
  }

  function abrirGenexusJunior() {
    window.open(
      "https://nicolasfuy.s3.amazonaws.com/certificados/genexusjunior.pdf",
      "_blank"
    );
  }
  function abriTallerInformatica() {
    window.open(
      "https://nicolasfuy.s3.amazonaws.com/certificados/tallerdeinformatica.pdf",
      "_blank"
    );
  }
  return (
    <>
      <Col className="col-12 mx-auto">
        <h1 className="card-title m-md-4 text-start hastag">#certifications</h1>
        <div className="margen-der marg-izq-sob">
          {/******************* Big Data *****************/}
          <div className="d-flex flex-row">
            <ImagenORT />
            <span className="mx-3 mb-3">
              <p className="card-title fw-bold">Univercidad ORT Uruguay</p>
              <p className="card-title tamanoTitulo">
                Big Data Certificate <em>(2024)</em>
              </p>
            </span>
          </div>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              {/*<p className="card-text">Approval rate: 83%</p>*/}
              <p className="card-text">
                Currently in progress, scheduled to conclude in August 2024
              </p>
            </div>
            <div>
              <button
                disabled
                className="btn btn-outline-dark btn-sm txt-btn-chico"
                onClick={abrirGenexusJunior}
              >
                certificate
              </button>
            </div>
          </div>
          <br />
          {/******************* GENEXUS *****************/}
          <div className="d-flex flex-row">
            <ImagenGenexus />
            <span className="mx-3 mb-3">
              <p className="card-title fw-bold">Globant</p>
              <p className="card-title tamanoTitulo">
                GeneXus Junior Analyst <em>(2023)</em>
              </p>
            </span>
          </div>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <p className="card-text">Approval rate: 83%</p>
            </div>
            <div>
              <button
                className="btn btn-outline-dark btn-sm txt-btn-chico"
                onClick={abrirGenexusJunior}
              >
                certificate
              </button>
            </div>
          </div>
          <br />
          <div id="vermas" style={{ display: showMore }}>
            {/******************* SOLAR DECATHLON *****************/}
            <div className="d-flex flex-row">
              <ImagenEnergia />
              <span className="mx-3 mb-3">
                <p className="card-title fw-bold">U.S. Departament of Energy</p>
                <p className="card-title tamanoTitulo">
                  Solar Decathlon
                  <em>(2015)</em>
                </p>
              </span>
            </div>
            <div className="d-flex flex-row">
              <div className="flex-grow-1">
                <p className="card-text">Position: 1º Place</p>
              </div>
              <div>
                <button
                  className="btn btn-outline-dark btn-sm txt-btn-chico"
                  onClick={abrirSolar}
                >
                  certificate
                </button>
              </div>
            </div>
            <br />
            {/******************* TALLER INFORMATICA *****************/}
            <div className="d-flex flex-row">
              <ImagenTaller />
              <span className="mx-3 mb-3">
                <p className="card-title fw-bold">Taller de Informatica</p>
                <p className="card-title tamanoTitulo">
                  Autocad draftsman <br />
                  <em>(2005)</em>
                </p>
              </span>
            </div>
            <div className="d-flex flex-row">
              <div className="flex-grow-1">
                <p className="card-text">Approval rate: 90%</p>
              </div>
              <div>
                <button
                  className="btn btn-outline-dark btn-sm txt-btn-chico"
                  onClick={abriTallerInformatica}
                >
                  certificate
                </button>
              </div>
            </div>
            <br />
          </div>
          <button className="btn btn-sm btn-outline-dark" onClick={onClick}>
            {mensaje}
          </button>
        </div>
      </Col>
    </>
  );
};

export default SobreMiCertificados;
