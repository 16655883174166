import "../../../src/App.css";

import React from "react";

import ImgComp from "../../img/todos/code1.png";

const ImagenApp = () => {
  return (
    <>
      <img className="centrado img-fluid" src={ImgComp} alt="" />
    </>
  );
};

export default ImagenApp;
