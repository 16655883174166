import React from "react";
import cn from "classnames";
import "./InicioVCard.scss";
import ImagenFirma from "../imagenes/ImagenFirma";
import ImagenQR from "../imagenes/ImagenQR";


const InicioVCard = () => {
  return (
    <div className="flip-card-outer mx-auto marg-top">
      <div
        className={cn("flip-card-inner", {
          "hover-trigger": true,
        })}
      >
        <div className="card front">
          <div className="card-body d-flex justify-content-center align-items-center">
              <ImagenFirma />
          </div>
        </div>
        <div className="card back">
          <div className="card-body d-flex justify-content-center align-items-center">
            <ImagenQR />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InicioVCard;
