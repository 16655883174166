import "../../../src/App.css";

import React from "react";

import ImgComp from "../../img/inicio/Linkedin.jpeg";

const ImagenLinkedin = () => {
  return (
    <img
      className="rounded-circle position-absolute marg-der imag-card tamano7 end-0 top-0 shadow-lg"
      src={ImgComp}
      alt=""
    />
  );
};

export default ImagenLinkedin;
