import "../../../src/App.css";

import React from "react";

import ImgComp from "../../img/todos/code.png";

const ImagenDependencias = () => {
  return (
    <>
      <img className="centrado img-fluid" src={ImgComp} alt="" />
    </>
  );
};

export default ImagenDependencias;
