import "../../../src/App.css";

import React from "react";

import ImgComp from "../../img/inicio/arquitectura.webp";

const ImagenArquitectura = () => {
  return (
    <>
      <img className="ancho-total tamano fit" src={ImgComp} alt="" />
    </>
  );
};

export default ImagenArquitectura;
